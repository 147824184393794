import React from "react";
import CustomerViews from "../components/CustomerViews/CustomerViews";
import HappyFaces from "../components/HappyFaces/HappyFaces";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo";
import FreeDiscount from "../components/Discount/FreeDiscount";
import HeroSectionFree from "../components/HeroSection/HeroSectionFree";

const FreeLikes = () => {
  return (
    <>
      <Seo
        title="Get Free Instagram Likes Trial Instantly at Stagroo"
        description="Take advantage of Stagroo's free Instagram likes trial. Get real, instant likes for your posts and grow your social presence effortlessly. Try it now!"
        canonical="/free-instagram-likes"
        keywords="free instaggram likes"
      />
      <Layout>
        <main>
          <HeroSectionFree
            heading={"Free Instagram Likes Trial"}
            description={
              "The Likes that Stagroo delivers are real and authentic, coming from legitimate IG users with real accounts on the app."
            }
          />

          <FreeDiscount from="likes" />
          <HappyFaces />
          <CustomerViews />
        </main>
      </Layout>
    </>
  );
};

export default FreeLikes;
